import React from 'react';
import PropTypes from 'prop-types';
import { normalizeSingleElementArray } from '../../utils';
import { normalizeANCType } from '../../utils/anc-utils';

const generateIconClass = (props) => {
  const typeProp = normalizeSingleElementArray(props.type);
  let normalizedType = normalizeANCType(typeProp) || 'lesson-plan';

  if (normalizedType.includes('other')) {
    normalizedType = 'other-information';
  }

  let size = '';

  switch (props.size) {
    case 'large':
      size = 'lg';
      break;
    case 'small':
      size = 'sm';
      break;
    default :
      size = 'md';
      break;
  }

  return `c-ckm-icon-anc--${size} c-ckm-icon-anc--${normalizedType}-${size}`;
};

const ANCIcon = (props) => {
  const iconClass = generateIconClass(props);

  return (
    <span className={iconClass} />
  );
};

export default ANCIcon;

ANCIcon.displayName = 'ANCIcon';

ANCIcon.propTypes = {
  type: PropTypes.array.isRequired,
  size: PropTypes.string
};

ANCIcon.defaultProps = {
  size: 'medium'
};
