const PAGES = {
  HOME: {
    name: 'home',
    type: 'NP-HP'
  },
  LOGIN: {
    name: 'login',
    type: 'AP-LP'
  },
  INSTITUTIONAL_LOGIN: {
    name: 'login:institution',
    type: 'AP-LP'
  },
  REGISTRATION: {
    name: 'registration',
    type: 'AP-UR'
  },
  REGISTRATION_SUCCESS: {
    name: 'registration-success',
    type: 'AP-UR'
  },
  BULK_REGISTRATION: {
    name: 'registration:bulk',
    type: 'AP-UR'
  },
  REMOTE_ACCESS: {
    name: 'remote-access',
    type: 'AP-RA'
  },
  USER_PROFILE: {
    name: 'settings',
    type: 'AP-EN'
  },
  SEARCH: {
    name: 'search',
    type: 'SP-ST'
  },
  BROWSE_BOOKS: {
    name: 'browse:books',
    type: 'SP-ST'
  },
  BROWSE_EMC: {
    name: 'browse:emc',
    type: 'SP-ST'
  },
  BROWSE_BOOK_TOC: {
    name: 'browse:toc',
    type: 'SP-ST'
  },
  BROWSE_EMC_TOC: {
    name: 'browse:emc:toc',
    type: 'SP-ST'
  },
  BROWSE_IMAGES: {
    name: 'browse:multimedia:images',
    type: 'SP-ST'
  },
  BROWSE_VIDEOS: {
    name: 'browse:multimedia:videos',
    type: 'SP-ST'
  },
  BROWSE_ANC: {
    name: 'browse:anc',
    type: 'SP-ST'
  },
  BROWSE_SPC: {
    name: 'browse:spc',
    type: 'SP-ST'
  },
  CONTENT_PLAYER: {
    name: 'content',
    type: 'CP-CR'
  },
  VIDEO: {
    name: 'video',
    type: 'CP-CR'
  },
  TOPIC: {
    name: 'topic',
    type: 'CP-CA'
  },
  CHANGE_PASSWORD: {
    name: 'change-password',
    type: 'AP-LR'
  },
  FORGOT_PASSWORD: {
    name: 'forgot-password',
    type: 'AP-UP'
  },
  RESET_PASSWORD: {
    name: 'reset-password',
    type: 'AP-UP'
  },
  CASE_PLAYER: {
    name: 'case-player',
    type: 'CP-CR'
  },
  YEAR_OF_STUDY: {
    name: 'generic:year-of-study',
    type: 'NP-GP'
  },
  PATH_CHOICE: {
    name: 'choose-institution',
    type: 'AP-CO'
  },
  PRESENTATIONS: {
    name: 'generic:presentations',
    type: 'NP-GP'
  },
  BOOKSHELF: {
    name: 'generic:bookshelf',
    type: 'NP-GP'
  },
  SAVED: {
    name: 'generic:saved',
    type: 'NP-GP'
  },
  LISTS: {
    name: 'generic:lists',
    type: 'NP-GP'
  },
  LIST_READER: {
    name: 'generic:list-reader',
    type: 'NP-GP'
  },
  VIDEOS: {
    name: 'generic:videos',
    type: 'NP-GP'
  },
  COOKIES: {
    name: 'cookies',
    type: 'NP-GP'
  },
  SETTINGS: {
    name: 'settings',
    type: 'NP-GP'
  },
  404: {
    name: 'error:404',
    type: 'ER-UE',
    errorType: 'SE-400-404'
  },
  UNAUTHORIZED: {
    name: 'error:unauthorized',
    type: 'ER-GE',
    errorType: 'SE-400-401'
  },
  500: {
    name: 'error:500',
    type: 'ER-PE',
    errorType: 'SE-500' // append specific 500 code to this
  },
  PREVIEW: {
    name: 'preview',
    type: 'AP-LP'
  },
  CKWIDGET: {
    name: 'ck-widget',
    type: ''
  }
};

module.exports = {
  PAGES
};
