import React, { useEffect, useState } from 'react';
import { videoPlayed } from '../../services/usageReporting';
import { trackEvent } from '../../utils/eventTrackUtils';
import EVENTS from '../../constants/adobeEvents';
import CKApi from '../../services/api-helper';
import { defineMessages } from 'react-intl';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

const messages = defineMessages({
  videoLoadingFailed: {
    id: 'VideoPlayer.error.videoLoading',
    defaultMessage: 'Video Loading Failed'
  }
});

/*
  * Get VideoJS options for the player
  *
  * @param {object} videoContent - The video's urls
  * @param {object} closedCaptioning - The video's closed captioning
  * @param {boolean} autostart - Flag to determine if the video should start automatically
  *
  * @returns {object} - VideoJS options
  */
const getVideoJsOptions = (videoContent, closedCaptioning, autostart, errorMessage) => ({
  fluid: true,
  controls: true,
  responsive: true,
  preload: 'auto',
  aspectRatio: '16:9',
  autoplay: autostart,
  tracks: closedCaptioning,
  playbackRates: [0.5, 1, 1.25, 1.5, 2],
  controlBar: {
    skipButtons: {
      backward: 10
    },
    volumePanel: {
      inline: false
    }
  },
  poster: videoContent.thumbnailUrl,
  notSupportedMessage: errorMessage,
  sources: [
    { src: videoContent.highResUrl, type: 'video/mp4' },
    { src: videoContent.medResUrl, type: 'video/mp4' },
    { src: videoContent.lowResUrl, type: 'video/mp4' }
  ]
});

/*
  * VideoJSPlayer component
  *
  * @param {string} props.eid - The video's eid
  * @param {string} props.type - The type if the video come from ANC or not
  * @param {string} props.videoUrl - The video's url
  * @param {string} props.videoTitle - The video's title
  * @param {number} props.videoStartTime - The video's start time
  * @param {boolean} props.videoDetailsNeeded - Flag to determine if the video details are needed
  * @param {function} props.getVideoDetails - Function to get the video details
  * @param {function} props.getVideoLength - Function to get the video length
  * @param {boolean} props.autostart - Flag to determine if the video should start automatically
  * @param {boolean} props.withAnalytics - Flag to determine if the video should have analytics
  * @param {function} props.callback - Function to return height of the transcript container
  * @param {function} props.onCloseVideo - Function to get start time of the video when it closes
  *
  * @returns {JSX.Element} - VideoJSPlayer component
  */
const VideoJSPlayer = (props) => {
  const {
    eid,
    intl,
    type,
    videoUrl,
    videoTitle,
    videoStartTime,
    videoDetailsNeeded,
    getVideoDetails,
    getVideoLength,
    autostart = true,
    withAnalytics = true,
    callback,
    onCloseVideo
  } = props;

  const [videoLoaded, setVideoLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const playerRef = React.useRef(null);
  const videoRef = React.useRef(null);

  const getRoundedTime = (num) => Math.round(num);

  const trackAdobeEvent = (eventName, player) => {
    const length = getRoundedTime(player.duration());
    const position = getRoundedTime(player.currentTime());
    trackEvent(eventName, {
      video: {
        id: eid,
        length,
        position
      }
    });
  };

  const setupAnalyticsEvents = (player, searchIndex) => {
    player.one('play', () => {
      videoPlayed(eid, searchIndex);
      trackAdobeEvent(EVENTS.VIDEO_START, playerRef.current);

      player.on('play', () => {
        trackAdobeEvent(EVENTS.VIDEO_PLAY, player);
      });
    });

    player.on('pause', () => {
      trackAdobeEvent(EVENTS.VIDEO_STOP, player);
    });

    player.on('ended', () => {
      trackAdobeEvent(EVENTS.VIDEO_COMPLETE, player);
    });
  };

  const onReadyPlayerSetup = (player) => {
    playerRef.current = player;

    const urlParams = new URLSearchParams(window.location.search);
    const startTime = videoStartTime || Number(urlParams.get('startTime'));

    if (startTime && !isNaN(startTime) && startTime > 0) {
      player.currentTime(startTime);
      player.on('timeupdate', () => startTime);
    }
  };

  const setupVideo = ({ data }) => {
    if (!data) return;

    const { HIGH, MEDIUM, LOW, VIDEO_POSTER, CLOSED_CAPTIONING } = data;

    if (videoDetailsNeeded) getVideoDetails(VIDEO_POSTER);

    const videoContent = {
      highResUrl: HIGH?.authenticatedStoreUrl || null,
      medResUrl: MEDIUM?.authenticatedStoreUrl || null,
      lowResUrl: LOW?.authenticatedStoreUrl || null,
      thumbnailUrl: VIDEO_POSTER?.authenticatedStoreUrl || null,
      searchIndex: HIGH?.searchIndex || 'GLOBAL',
      title: videoTitle || VIDEO_POSTER?.itemTitle || 'Untitled Video',
      caption: CLOSED_CAPTIONING?.authenticatedStoreUrl || null
    };

    const captions = CLOSED_CAPTIONING || {authenticatedStoreUrl: props.vttFile};
    const closedCaptioning = captions
      ? [{
        src: captions.authenticatedStoreUrl,
        kind: 'captions',
        label: 'captions on'
      }] : null;

    setVideoLoaded(true);

    if (videoRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = videojs(videoElement,
        getVideoJsOptions(videoContent, closedCaptioning, autostart, errorMessage),
        () => onReadyPlayerSetup(player)
      );

      if (withAnalytics) {
        setupAnalyticsEvents(player, videoContent.searchIndex);
      }
    }
  };

  const getVideoData = async (eid, videoUrl) => {
    const setupVideoData = (url) => ({
      HIGH: { authenticatedStoreUrl: url },
      MEDIUM: { authenticatedStoreUrl: url },
      LOW: { authenticatedStoreUrl: url },
      VIDEO_POSTER: null,
      CLOSED_CAPTIONING: null
    });

    if (videoUrl) {
      setupVideo({ data: setupVideoData(videoUrl) });
      return;
    }

    try {
      const apiEndpoint = type === 'anc'
        ? `/student/api/content/anc/${eid}`
        : `/student/api/content/video/${eid}`;
      const { data } = await CKApi.get(apiEndpoint);
      setupVideo({ data: type === 'anc' ? setupVideoData(data) : data });
    } catch (error) {
      console.error('Error loading video:', error);
      setErrorMessage(intl.formatMessage(messages.videoLoadingFailed));
    }
  };

  const handleBeforeUnload = (event) => {
    if (event) {
      event.returnValue = '';
    }
    if (onCloseVideo && playerRef.current) onCloseVideo(getRoundedTime(playerRef.current.currentTime()));
  };

  useEffect(() => {
    if (!playerRef.current) {
      getVideoData(eid, videoUrl);
    }
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      if (videoLoaded) {
        handleBeforeUnload();
      }
      if (playerRef.current && !playerRef.current.isDisposed()) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [videoRef, eid, videoUrl]);

  useEffect(() => {
    if (callback && playerRef.current && videoLoaded) {
      callback(playerRef.current.el_.clientHeight);
    }
    if (getVideoLength && playerRef.current) {
      getVideoLength(getRoundedTime(playerRef.current.duration()));
    }
  }, [videoLoaded, callback, getVideoLength]);

  return (
    <div data-testid='video-js-player' ref={videoRef} className='' />
  );
};

export default VideoJSPlayer;
