import React from 'react';
import { Button } from '@els/els-react--button';

const ErrorBody = ({
  description,
  subHeader,
  isLoggedIn,
  canGoBack,
  handleGoBack,
  doRedirect,
  erroToBrowse,
  errorToLoginPage,
  errorButtonBack
}) => {
  return (
    <div className='u-ckm-inner-container c-ckm-error__container' data-testid='404-message'>
      <div className='c-ckm-error__wrapper'>
        <svg
          className='c-ckm-error__icon' width='128' height='128' viewBox='0 0 128 128' fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <use xlinkHref='#not-found' />
        </svg>
        <div className='c-ckm-error__wrapper-text'>
          <h1 className='c-ckm-error__header'>
            {subHeader}
          </h1>
          <div className='c-ckm-error__description'>
            {
            description
          }
          </div>
          <Button
            disabled={!canGoBack}
            type={Button.Types.SECONDARY}
            className='c-ckm-error__button'
            onClick={handleGoBack}
            data-testid='go-back'
          >
            {errorButtonBack}
          </Button>
          {isLoggedIn
          ? <Button
              type={Button.Types.PRIMARY}
              className='c-ckm-error__button'
              onClick={() => {
                localStorage.setItem('urlArray', '[]');
                doRedirect('/student/');
              }}
              data-testid='go-browse'
            >
            {erroToBrowse}
          </Button>
          : <Button
              type={Button.Types.PRIMARY}
              className='c-ckm-error__button'
              onClick={() => {
                localStorage.setItem('urlArray', '[]');
                doRedirect('/student/login');
              }}
              data-testid='go-login'
            >
            {errorToLoginPage}
          </Button>}
        </div>
      </div>
    </div>
  );
};

export default ErrorBody;
