// Environments
module.exports.PRODUCTION = 'production';
module.exports.DEVELOPMENT = 'development';
module.exports.LOCAL = 'local';
module.exports.TEST = 'test';

// Endpoints
module.exports.PROD_URL = 'https://www.clinicalkey.com';
module.exports.PROD_EAST_URL = 'https://prod-east.clinicalkey.com';
module.exports.CERT_URL = 'https://ck2-cert.clinicalkey.com';
module.exports.LOCAL_NODE_URL = 'http://dev.clinicalkey.com:8080';

module.exports.PROD_SHIB_URL = 'https://auth.elsevier.com/ShibAuth/institutionLogin';
module.exports.CERT_SHIB_URL = 'https://loadcq-shibauth-np.elsevier.com/ShibAuth/institutionLogin';

module.exports.CA_PROD_URL = 'https://api.3d4medical.com';
// module.exports.CA_NONPROD_URL = 'https://api.nonprod.3d4medical.com';

module.exports.PROD_REGEXP = /^https:\/\/(www|prod-east|prod-west)\./;

// Environment Varialbes
module.exports.NODE_ENV = 'NODE_ENV';
module.exports.NODE_SERVICE = 'NODE_SERVICE';

module.exports.PRODUCT_NAME = 'CK_MEDED';

module.exports.LEARN_ACCESS_FENCE = 'CKMEDED_LEARN_ACCESS';
module.exports.TEST_ACCESS_FENCE = 'CKMEDED_TEST_ACCESS';
module.exports.PRACTICE_ACCESS_FENCE = 'CKMEDED_PRACTICE_ACCESS';

module.exports.ANC_ACCESS_FENCE = 'CKMEDED_ALLOW_ANC_CONTENT';
module.exports.NNNCONSULT_ACCESS_FENCE = 'CKMEDED_NNN_CONSULT_ACCESS';

module.exports.PRESENTATION_ACCESS_FENCE = 'ALLOW_PPT';

module.exports.CKMEDED_IND_SHOWLIST = 'CKMEDED_IND_SHOWLIST';

module.exports.SUPPRESS_REG_INPUT_FENCE = 'CKMEDED_HIDE_REG_FORM';

module.exports.INDEXES = ['GLOBAL', 'FRENCH', 'SPANISH', 'GERMAN', 'NURSING', 'PORTUGUESE'];

module.exports.DTM_SCRIPT_PROD = '//assets.adobedtm.com/4a848ae9611a/1ed478368a86/launch-bbf8762341de.min.js';
module.exports.DTM_SCRIPT_NONPROD = '//assets.adobedtm.com/4a848ae9611a/1ed478368a86/launch-54872f98c2a4-staging.min.js';

module.exports.EOLS_ROLE_COOKIE = 'eolsRole';
module.exports.EOLS_INST_COOKIE = 'eolsInstitutionId';
module.exports.EOLS_RESOURCE_COOKIE = 'eolsResourceLinkId';
module.exports.EOLS_COURSE_COOKIE = 'eolsCourseTitle';

module.exports.TICURL_AUTH_COOKIE = 'ticUrlAuth';

module.exports.SEARCH_PAYLOAD_ID_COOKIE = 'searchPayloadId';

module.exports.EMBED_COOKIE = 'cks-embed';

module.exports.SETTINGS_FEATURE_OVERRIDES = [{ label: 'Anonymous Access', cookieLabel: 'anonymousAccess'}];

module.exports.NURSING_INSTRUCTOR_ROLE = 'CK MedEd Instructor';

module.exports.PRACTICE_GUIDELINE_MASTER = 'CK_Student_Cases_Master';

module.exports.PRACTICE_ECR_ID = 'ECR-10425118';
module.exports.USE_CRE_MULTITENANCY = false;

module.exports.CLIENT_PLUGIN_NAME = 'client';
module.exports.API_PLUGIN_NAME = 'api';

module.exports.EMULATOR_ACCOUNT = {
  product: 'CK_MEDED',
  ip: '198.176.81.33',
  session_id: '2E1BC32FAB2C7F2189DCC4F350F9FDF3',
  user_id: '8849',
  internal_id: '1435109',
  account_id: '333625',
  account_name: 'ClinicalKey A&E Emulator Account',
  account_number: 'C000333625',
  fences: [
    'REQUIRE_BOOK_PDF_LOGIN',
    'REQUIRE_JOURNAL_PDF_LOGIN'
  ]
};

module.exports.INSTRUCTOR_ROLE = {
  DOCTOR: 'Doctor',
  FACULTY: 'Faculty',
  LIBRARIAN: 'Librarian',
  RESEARCHER: 'Researcher',
  TEACHER: 'Teacher',
  OTHER: 'Other',
  RESEARCH_ADMINISTRATOR: 'Research Administrator',
  CEO_CTO_CSO: 'CEO/CTO/CSO',
  RND_MANAGER_TECHNOLOGY_LEAD: 'R&D Manager/Technology Lead',
  CONSULTANT: 'Consultant',
  PROFESSOR: 'Professor',
  BUSINESS_DEVELOPMENT_MANAGER: 'Business Development Manager',
  SOFTWARE_DEVELOPER: 'Software Developer',
  CIO: 'CIO',
  CMO_CMIO: 'CMO/CMIO',
  DOCTOR_CONSULTANT: 'Doctor > Consultant',
  DOCTOR_INTERN: 'Doctor > Intern',
  DOCTOR_MEDICAL_FELLOW: 'Doctor > Medical Fellow',
  DOCTOR_PHYSICIAN_ASSISTANT: 'Doctor > Physician Assistant',
  DOCTOR_RESIDENT_LEARNING_PHYSICIAN: 'Doctor > Resident/Learning Physician',
  DOCTOR_SURGEON: 'Doctor > Surgeon',
  NURSE: 'Nurse',
  NURSE_PRESCRIBING_NURSE: 'Nurse > Prescribing Nurse',
  PROFESSOR_ASSOCIATE_PROFESSOR: 'Professor > Associate Professor',
  LECTURER: 'Lecturer'
};

module.exports.INDEX_OVERRIDE = 'SPANISH';
