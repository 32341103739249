import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { MainContext } from '../../context/main-context';
import { Flyout } from '@els/els-react--flyout';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';
import { TextInput } from '@els/els-react--text-input';
import { Modal } from '@els/els-react--modal';

const messages = defineMessages({
  shareButton: {
    id: 'ListsPage.label.share',
    defaultMessage: 'Share'
  },
  shareNotification: {
    id: 'ListsPage.notification.share',
    defaultMessage: 'Copied link'
  },
  shareFlyoutInputLabel: {
    id: 'shareButton.shareFlyout.inputLabel',
    defaultMessage: 'Copy link'
  },
  shareFlyoutCopyButton: {
    id: 'shareButton.shareFlyout.copyButton',
    defaultMessage: 'Copy'
  },
  shareFlyoutShareEmail: {
    id: 'shareButton.shareFlyout.shareEmail',
    defaultMessage: 'Share via email'
  },
  shareEmailSubject: {
    id: 'shareButton.shareEmail.subject',
    defaultMessage: 'I would like to share with you "{collectionName}" collection'
  },
  shareEmailBody: {
    id: 'shareButton.shareEmail.body',
    defaultMessage: 'Here is the link to my collection'
  },
  shareFlyoutCopyToast: {
    id: 'shareButton.shareFlyout.copyToast',
    defaultMessage: 'Link copied to your clipboard'
  }
});

const ShareModal = (props) => {
  const { intl, productConfig, addToast } = useContext(MainContext);

  const generateShareURL = () => {
    const { url, preview = {} } = props;
    let shareURL;

    if (!url) {
      shareURL = `${window.location.origin}${window.location.pathname}?origin=share`;
    } else {
      shareURL = `${window.location.origin}${productConfig.path}${url}?origin=share`;
    }

    if (preview.title) {
      shareURL += `&title=${encodeURIComponent(preview.title)}`;
    }
    if (preview.meta) {
      shareURL += `&meta=${encodeURIComponent(preview.meta)}`;
    }
    if (preview.img) {
      shareURL += `&img=${encodeURIComponent(preview.img)}`;
    }
    if (preview.picto) {
      shareURL += `&picto=${encodeURIComponent(preview.picto)}`;
    }

    return shareURL;
  };

  const shareURL = generateShareURL();

  const copyShareLink = (evt) => {
    navigator.clipboard.writeText(shareURL);
    addToast({
      body:
        `<SuccessMessage>
          ${intl.formatMessage(messages.shareFlyoutCopyToast)}
        </SuccessMessage>`,
      isVisible: true,
      autoHide: true,
      icon: '#icon-sprite_els-hmds-icon-checkmark',
      iconColor: 'positive',
      color: 'positive'
    });
    props.handleClose();
  };

  const shareViaEmailClick = (evt) => {
    const encodedShareURL = encodeURIComponent(shareURL);
    const encodedCollectionName = encodeURIComponent(props.name);
    const subject = intl.formatMessage(props.menuContent.emailSubject, {name: encodedCollectionName});
    const body = `${intl.formatMessage(props.menuContent.emailBody)} "${encodedCollectionName}":%0D${encodedShareURL}%0D%0D%0A%0D%0D%0A${intl.formatMessage(props.menuContent.info)}`;
    window.location = `mailto:?subject=${subject}&body=${body}`;
    props.handleClose();
  };

  return (
    <Modal onClose={props.handleClose} focusTrapProps={{ active: false}}>
      <div className='c-ckm-share-button__menu'>
        <div className='o-els-flex-layout'>
          <div className='o-els-flex-layout__item o-els-flex-layout__item--grow'>
            <h3>{intl.formatMessage(props.menuContent.header)}</h3>
          </div>
        </div>
        <div>
          <p className='c-ckm-share-button__menu-description'>{intl.formatMessage(props.menuContent.info)}</p>
          <div className='o-els-flex-layout c-ckm-share-button__menu--form'>
            <div className='o-els-flex-layout__item o-els-flex-layout__item--grow'>
              <TextInput
                label={intl.formatMessage(messages.shareFlyoutInputLabel)}
                value={shareURL}
                readOnly
              />
            </div>
            <div className='o-els-flex-layout__item'>
              <Button
                type={Button.Types.PRIMARY}
                onClick={copyShareLink}
                className='c-els-button--small u-els-margin-left-1o2 c-ckm-share-button__menu--copy-button'
                data-testid='shareCopyLinkBtn'
                id='shareCopyLinkBtn'
              >
                <Icon
                  a11y={{ name: intl.formatMessage(messages.shareFlyoutCopyButton) }}
                  sprite={Icon.Sprites.DUPLICATE_DOCUMENT}
                  id='lists-share-icon'
                  size={Icon.Sizes.S}
                  textAlignment='middle'
                  className='u-els-margin-right-1o2'
                  isVisible
                />
                {intl.formatMessage(messages.shareFlyoutCopyButton)}
              </Button>
            </div>
          </div>
          <Button
            type={Button.Types.LINK}
            onClick={shareViaEmailClick}
            className='u-els-margin-top c-ckm-share-button__vie_email'
            data-testid='shareViaEmailBtn'
            id='shareViaEmailBtn'
          >
            {intl.formatMessage(messages.shareFlyoutShareEmail)}
            <Icon
              a11y={{ name: intl.formatMessage(messages.shareFlyoutShareEmail) }}
              sprite={Icon.Sprites.UP_RIGHT}
              id='shareViaEmailIcon'
              size={Icon.Sizes.S}
              textAlignment='middle'
              className='u-els-margin-left-1o2'
              isVisible
              isTextFirst
            />
          </Button>
        </div>

      </div>
    </Modal>
  );
};

const CommonShareButton = (props) => {
  const { intl } = useContext(MainContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const shareButtonClick = (evt) => {
    evt.preventDefault();
    setIsModalVisible(true);
    props.buttonClickCallback?.(); // eslint-disable-line no-unused-expressions
    evt.target.blur();
  };

  const closeFlyout = (evt) => {
    setIsModalVisible(false);
  };

  const getButtonClass = (btnType) => {
    switch (btnType) {
      case Button.Types.SECONDARY:
        return 'share-button__secondary';
      case Button.Types.PRIMARY:
        return 'c-els-button--small';
      default:
        return '';
    }
  };

  return (
    <>
      <Flyout
        className='c-ckm-share-button'
        trigger='click'
        placement={props.flyoutPlacement}
        flyout={<ShareModal {...props} handleClose={closeFlyout} />}
        shouldCloseOnESC
        id='share-btn-flyout'
        isVisible={isModalVisible}
        theme='simple'
      >
        <Button
          type={props.type}
          onClick={shareButtonClick}
          className={`${props.className} ${getButtonClass(props.type)}`}
          data-testid='shareBtnTestId'
          id='shareBtnId'
        >
          <Icon
            a11y={{ name: intl.formatMessage(messages.shareButton) }}
            sprite={Icon.Sprites.SHARE}
            id={props.id || 'lists-share-icon'}
            size={props.iconSize}
            textAlignment='middle'
            className=' u-els-margin-right-1o2'
            isVisible
          />
          <span className={props.className && `${props.className}--label`}>{props.showLabel && intl.formatMessage(messages.shareButton)}</span>
        </Button>
      </Flyout>
    </>
  );
};

CommonShareButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  iconSize: PropTypes.string,
  flyoutPlacement: PropTypes.string,
  menuContent: PropTypes.shape({
    header: PropTypes.object,
    info: PropTypes.object,
    emailSubject: PropTypes.object,
    emailBody: PropTypes.object
  })
};

CommonShareButton.defaultProps = {
  iconSize: Icon.Sizes.XS,
  flyoutPlacement: 'bottom-end'
};

CommonShareButton.displayName = 'CommonShareButton';

export default CommonShareButton;
