import React, { useContext } from 'react';
import { defineMessages } from 'react-intl';
import { Button } from '@els/els-react--button';
import { MainContext } from '../../context/main-context';
import '../../assets/img/picto-multimedia.svg';

const messages = defineMessages({
  login: {
    id: 'AnonymousLoginBanner.loginButtonLabel',
    defaultMessage: 'Log in'
  }
});

const AnonymousLoginBanner = ({ image, headerText, bodyText, testId = ''}) => {
  const { intl, doRedirect } = useContext(MainContext);
  return (
    <div className='u-els-text-center u-els-margin-bottom-3x'>
      <svg className='u-els-margin-bottom'>
        <use xlinkHref={image} />
      </svg>
      <h3>{headerText}</h3>
      <p>{bodyText}</p>
      <div className=''>
        <Button
          type={Button.Types.PRIMARY}
          onClick={() => { doRedirect('/login'); }}
          aria-label={intl.formatMessage(messages.login)}
          title={intl.formatMessage(messages.login)}
          id='login-button'
          data-testid={`anonymous${testId && '_' + testId}_login-btn`}
        >
          {intl.formatMessage(messages.login)}
        </Button>
      </div>
    </div>
  );
};

export default AnonymousLoginBanner;
