import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from '../../hooks';
import HTMLString from './HTMLString';

const HTMLStringWithLang = ({content, langDetector}) => {
  const intl = useIntl();
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (!langDetector?.findLanguage || !content?.trim() || content.trim().length < 5) {
      return;
    }

    const detectLanguage = () => {
      const result = langDetector.findLanguage(content);

      setLanguage(result.language);
    };

    detectLanguage();
  }, [content, langDetector]);

  const langProp = !!language && language !== intl.locale ? language : null;

  return (
    <HTMLString content={content} lang={langProp} />
  );
};

export default HTMLStringWithLang;

HTMLStringWithLang.propTypes = {
  content: PropTypes.string.isRequired
};
