export const CASE_ANSWER_CONSTANTS = {
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  NEUTRAL: 'NEUTRAL'
};

export const CASE_SCORE_TYPES = {
  SUBOPTIMAL: 'Suboptimal',
  OPTIMAL: 'Optimal',
  CRITICAL: 'Critical',
  INPROGRESS: 'InProgress'
};

export const EXPECTATION_TYPES = {
  ABOVE_EXPECTATIONS: 'AboveExpectations',
  MEETS_EXPECTATIONS: 'MeetsExpectations',
  BELOW_EXPECTATIONS: 'BelowExpectations'
};

export const RECORDING_STATES = {
  IDLE: 'idle',
  RECORDING: 'recording',
  RECORDED: 'recorded',
  PLAYING: 'playing',
  PERMISSION_ERROR: 'permissionError',
  NO_DEVICE_ERROR: 'noDeviceError',
  GENERIC_ERROR: 'genericError'
};

export const SMALL_BUTTONS_BREAKPOINT = 1439;

export const MIN_INPUT_CHARS_DIFF = 2;

export const DIFFERENTIAL_LIMIT = 3;

export const ANSWER_TYPES = {
  CHOSE_CORRECT: 9999,
  CHOSE_NEUTRAL: 0,
  NOT_CHOSE_CORRECT: -9999,
  NOT_TO_DISPLAY: 1,
  CHOSE_INCORRECT: -1,
  NOT_CHOSE_INCORRECT_MIN: -100
};

export const DISPLAY_SECTIONS = {
  CASE_OUTCOME: 'CaseOutcome',
  DIFFERENTIAL: 'DifferentialDiagnosis',
  DIFFERENTIAL_ANALOGUE: 'DifferentialDiagnoses',
  PHYSICAL_EXAMINATION: 'Examinations',
  EXAMINATION_RESULT: 'ExaminationsResults',
  INVESTIGATIONS_RESULT: 'InvestigationsResults',
  EXAMINATIONS: 'Examinations',
  INVESTIGATIONS: 'Investigations',
  DIAGNOSIS: 'Diagnosis',
  TREATMENT: 'Treatment',
  TREATMENTS: 'Treatments',
  DICTATE_SUMMARY: 'DictateSummary',
  SPEECHBUBBLESAPP: 'SpeechBubblesApp',
  SPEECHBUBBLESUSER: 'SpeechBubblesUser',
  SPEECHBUBBLESDOCTOR: 'SpeechBubblesSeniorDoctor',
  DICTATIONREVIEW: 'DictationReview',
  VITALSIGNS: 'VitalSigns',
  SPEECH_BUBBLES_ALERT: 'SpeechBubblesSeniorDoctorAlert',
  PATIENT_STORY: 'PatientStory',
  CRITICAL_REVIEW: 'SeniorClinicalCriticalReview'
};

export const DR_RESPONSE_TYPE = {
  RECORD: 'record',
  TEXT: 'text',
  HIDDEN: 'hidden'
};

export const DIFFERENTIAL_STATES = {
  INPUT: 'input',
  CONFIRM: 'confirm',
  SENDED: 'sended'
};

export const CASE_STATES = {
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED'
};

export const ACCURACY_TYPES = {
  DIFF_DIAG_ACCURACY: 'diffDiagAccuracy',
  EXAMS_ACCURACY: 'examsAccuracy',
  INV_ACCURACY: 'invAccuracy',
  DIAG_ACCURACY: 'diagAccuracy',
  TREAT_ACCURACY: 'treatAccuracy'
};

export const PAGE_STATES_REPORTING = {
  OUTCOMES: 'Outcomes',
  ACCURACY: 'Accuracy'
};

export const PAGE_TYPES_REPORTING = {
  HIGHEST_VIEW: 'Highest_View',
  CASE_PERFORMANCE_STUDENT: 'Case_performance_student',
  HIGHEST_VIEW_SINGLE_STUDENT: 'Highest_View_Single_Student',
  SPECIALTY: 'Specialty',
  SPECIALTY_STUDENT: 'Specialty_student',
  SPECIALTY_SINGLE_STUDENT: 'Specialty_Single_Student',
  CASE_SINGLE: 'Case_Single',
  CASE_SINGLE_STUDENT: 'Case_Single_Student'
};

export const CONTROL_TABS = {
  CASES: 'CASES',
  CR_STEPS: 'CR_STEPS'
};

export const CONTROL_TABS_WEEKLY_AVERAGE = {
  DIFFERENTIALS: 'DIFFERENTIALS',
  EXAMINATIONS: 'EXAMINATIONS',
  INVESTIGATIONS: 'INVESTIGATIONS',
  DIAGNOSIS: 'DIAGNOSIS',
  TREATMENTS: 'TREATMENTS'
};

export const WEEKLY_AVERAGE_SORTING_SERVER_VALUES = {
  DIFFERENTIALS: 'diffDiagAccuracy',
  EXAMINATIONS: 'examsAccuracy',
  INVESTIGATIONS: 'invAccuracy',
  DIAGNOSIS: 'diagAccuracy',
  TREATMENTS: 'treatAccuracy',
  AVERAGE: 'averageAccuracy'
};

export const REPORTING_HOME_URLS = {
  STUDENT: 'performance',
  INSTRUCTOR: 'reporting',
  INSTRUCTOR_SINGLE_STUDENT: 'reporting/single/'
};

export const DATA_GROUPING_TYPES = {
  SPECIALTY: 'specialty',
  CONDITION: 'patientCondition'
};
