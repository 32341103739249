import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl, defineMessages } from 'react-intl';
import jsCookie from 'js-cookie';
import { append, reject } from 'ramda';
import CKApi from '../services/api-helper';
import {
  ELSGlobalAppHeader,
  ELSCommonConfig,
  ELSTokenHelper,
  ELSCommonUIConstants,
  ELSServerConstants,
  ELSAccessibilityFocusState,
  ELSTokenServiceRegistrar,
  ELSIdleProvider,
  ELSMedEdService,
  ELSCloudFront,
  ELSStorageHelper,
  ELSQuestionBankService,
  ELSUtilityHelper,
  ELSSubNavbar,
  ECSGlobalAppHeader
  // ECSGlobalAppHeader // Commented before we get user with Skills acces
} from '@els/meded-ui-common-react';
import PathChoiceForm from './PathChoiceForm';
import queryStringUtils from '../utils/queryStringUtils';
import localeConfig from '../config/locale';
import classNames from 'classnames';
import { eolsLogin } from '../services/eols';
import { MainContext } from '../context/main-context';
import { USER_TYPE, USER_INFO } from '../constants';
import User from '../../server/utils/User';
import ToastsContainer from './common/ToastsContainer';
import AppFooter from './common/AppFooter';
import DOMPurify from 'dompurify';
import { fetchFeatureFlags } from '../services/feature-flags';
import {marginTopAnchorTag} from '../hooks/useDynamicScrollMarginTop';
import ZoomHandler from './ZoomHandler';
import {CkReactCoreTopHeaderNavigation} from '@els/ck-react--core-top-header-navigation';
import { AnonymousFlyoutBanner } from './common/AnonymousFlyoutBanner';

const messages = defineMessages({
  flyoutAnonBannerHeaderText: {
    id: 'Navigation.bookshelf.flyout.anonBannerHeaderText',
    defaultMessage: 'Want to read this offline, take notes and highlight?'
  },
  flyoutAnonBannerBodyText: {
    id: 'Navigation.bookshelf.flyout.anonBannerBodyText',
    defaultMessage: 'Log in to add this book to Bookshelf e-reader app.'
  }
});

const { formatUriContext } = require('../../server/utils/url-utils');
if (process.env.NODE_ENV !== 'production') {
  if (process.env.A11Y_TESTING === 'on') {
    const axe = require('react-axe');
    axe(React, ReactDOM, 1000);
  }
}
class PageWrapper extends Component {
  constructor (props) {
    super(props);
    if (!ELSCommonConfig.buildUrl) {
      ELSCommonConfig.setBuildUrl(ELSServerConstants[ELSServerConstants.DataSource].gatewayBaseURL);
    }
    ELSCommonConfig.setStorageDriver(ELSCommonUIConstants.security.Locker.DefaultDriver);
    ELSCommonConfig.setTokenServicePath(ELSCommonUIConstants.TOKEN_PATH);
    ELSTokenServiceRegistrar.initializeFromReload();
  }

  state = {
    pathChoices: null,
    userRole: '',
    eolsLoginFinished: false,
    headerHeight: 0,
    toastsConfig: [],
    isOpenVideoDescriptionPanel: false,
    showInfoMessage: true,
    courseId: localStorage.getItem('courseId') ? parseInt(localStorage.getItem('courseId')) : -1,
    isSelfStudyUser: false,
    isZoomed: false
  };

  handleZoomChange = (isZoomed) => {
    this.setState({ isZoomed });
  };

  headerRef = createRef();
  footerRef = createRef();

  componentWillMount = () => {
    const user = new User(this.props.user);
    const ticUrlAuth = jsCookie.get('ticUrlAuth') === 'true';
    this.setState({ticUrlAuth: ticUrlAuth});
    this.setFeatureFlags();
    if (user.isPathChoice()) {
      this.setState({
        pathChoices: user.path_choices
      });
    } else if (this.props.changeOrg) {
      this.doChangeOrg();
    } else if (user.isIndividual() && user.hasAssessmentAccess()) {
      if (ticUrlAuth || !ELSTokenServiceRegistrar.isRegistered()) {
        this.performEolsLogin();
      } else {
        this.setState({ eolsLoginFinished: true, userRole: ELSTokenHelper.getUserRole() });
      }
    }

    ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.DefaultDriver)
      .namespace('analytics')
      .put('pageData', pageData); // eslint-disable-line no-undef
  };

  performEolsLogin = async () => {
    try {
      const userRole = await eolsLogin();
      this.setState({ eolsLoginFinished: true, userRole });

      this.setMockExams();
    } catch (error) {
      console.error(error);
      // do nothing on EOLS login failure
    }
  };

  setMockExams = () => {
    ELSQuestionBankService.getQuestionBanks().then((res) => {
      const isUSMLEMockExams = (banks) => Boolean(banks.filter((bank) => ELSUtilityHelper.determineIfUSMLEMockExams(bank.isbn)).length);
      const isAKTMockExams = (banks) => Boolean(banks.filter((bank) => ELSUtilityHelper.determineIfAKTMockExams(bank.isbn)).length);
      this.setState({
        hasUSMLEContent: isUSMLEMockExams(res?.data),
        hasAKTExam: isAKTMockExams(res?.data)
      });
    });
  };

  componentDidMount = () => {
    this.setState({
      headerRef: this.headerRef.current,
      footerRef: this.footerRef.current
    });

    this.setMockExams();
  };

  doRedirect = (url, target, isRootUrl) => {
    const { path } = !isRootUrl ? this.props.productConfig : { path: ''};
    const sanitizedPath = DOMPurify.sanitize(path);
    const sanitizedUrl = DOMPurify.sanitize(url);

    if (target) {
      window.open(formatUriContext(sanitizedUrl, sanitizedPath), target).focus();
    } else {
      window.location.href = formatUriContext(sanitizedUrl, sanitizedPath);
    }
  };

  showPathChoice = (choices) => {
    this.setState({ pathChoices: choices });
  };

  handleCourseChange = course => {
    this.setState({ courseId: course.value });
    localStorage.setItem('courseId', course.value);
  };

  handleCourseChangeById = courseId => {
    this.setState({ courseId: courseId });
    localStorage.setItem('courseId', courseId);
  };

  isSelfStudyUserCallback = (isSelfStudyUser) => {
    this.setState({isSelfStudyUser: isSelfStudyUser});
  };

  doChangeOrg = () => {
    CKApi.post('/student/api/auth/path-choice', {})
      .then(({ data }) => {
        this.showPathChoice(data.path_choices);
      });
  };

  setFeatureFlags = () => {
    const promise = fetchFeatureFlags();
    promise
      .then((response) => {
        this.setState({ featureFlags: response });
      });
  };

  redirectToTarget = (target, user) => {
    const nnnPathString = '/nnn/nanda/codigos';

    // if user switching from the NNNOnly account
    if (!user.isNNNOnly() && target.includes(nnnPathString)) {
      this.doRedirect('/');
    } else {
      this.doRedirect(target || '/');
    }
  };

  handlePathChoiceComplete = async (pathChoiceResponse) => {
    if (!pathChoiceResponse) {
      return null;
    }
    const parsedQueryString = queryStringUtils.parse(window.location.search);
    const user = new User(pathChoiceResponse.data);

    if (parsedQueryString?.target?.includes('/test') && user.hasAssessmentAccess()) {
      await eolsLogin();
    }

    if (this.props.studyToolsLoginType) {
      // if non-registered shibboleth, send to registration page first
      if (user.isAnonShibboleth()) {
        return this.doRedirect(`/registration?studyToolsLogin=${DOMPurify.sanitize(this.props.studyToolsLoginType)}`);
      }
      const onlineStudyTools = this.props.studyToolsLoginType === 'online';

      this.doRedirect(`/api/vst/redirect?online=${onlineStudyTools}`);
    } else if (parsedQueryString.target) {
      this.redirectToTarget(parsedQueryString.target, user);
    } else if (this.props.changeOrg) {
      this.doRedirect('/');
    } else {
      window.location.reload();
    }
  };

  handleSessionTimeout = () => {
    ELSMedEdService.cleanUpState();
    this.doRedirect('/logout');
  };

  addToast = (newToastConfig) => {
    this.setState({ toastsConfig: append(newToastConfig, this.state.toastsConfig) });
  };

  removeToast = (toastId) => {
    this.setState({
      toastsConfig: reject(toast => toast.id === toastId, this.state.toastsConfig)
    });
  };

  toggleFooterAndHeaderOffset = (value) => {
    this.setState({ isOpenVideoDescriptionPanel: value });
  };

  toggleInfoMessage = () => {
    this.setState({ showInfoMessage: !this.state.showInfoMessage });
  };

  bookshelfButtonAnonymousFlyout = () => {
    return (
      <AnonymousFlyoutBanner
        headerText={this.props.intl.formatMessage(messages.flyoutAnonBannerHeaderText)}
        bodyText={this.props.intl.formatMessage(messages.flyoutAnonBannerBodyText)}
        testId='bookshelf'
      />
    );
  };

  render () {
    const { pathChoices, userRole, hasUSMLEContent, hasAKTExam } = this.state;
    const { productConfig } = this.props;
    const user = new User(this.props.user);
    const { isZoomed } = this.state;
    user.hasUSMLEContent = hasUSMLEContent;
    user.hasAKTExam = hasAKTExam;

    if (this.props.userSettings) {
      this.props.userSettings.role = this.state.userRole === USER_TYPE.INSTRUCTOR ? USER_INFO.PROFESSIONAL : this.props.userSettings.role;
      this.props.userSettings.ticUrlAuth = this.state.ticUrlAuth;

      if (typeof localStorage !== 'undefined' && localStorage !== null) {
        localStorage.removeItem('professional');
        if (this.props.userSettings.role === USER_INFO.PROFESSIONAL) {
          localStorage.setItem('professional', this.props.userSettings.role === USER_INFO.PROFESSIONAL);
        }
      }
    }

    const mainContextObject = {
      user,
      showPathChoice: this.showPathChoice,
      activeIndex: user.getDefaultIndex(),
      localeConfig: localeConfig(this.props.locale, this.props.productConfig.context),
      headerRef: this.state.headerRef,
      footerRef: this.state.footerRef,
      productConfig: this.props.productConfig,
      regionFlags: this.props.regionFlags,
      intl: this.props.intl,
      doRedirect: this.doRedirect,
      featureFlags: this.state.featureFlags,
      shibbolethBaseUrl: this.props.shibbolethBaseUrl,
      userSettings: this.props.userSettings,
      showYearOfStudy: (
        this.props.productConfig.context !== 'resident' &&
        this.props.userSettings.error !== true &&
        userRole !== USER_TYPE.INSTRUCTOR &&
        this.props.userSettings.role !== USER_INFO.PROFESSIONAL &&
        (user.hasFoundationAccess() || user.hasAssessmentAccess() || user.hasPracticeAccess())
      ),
      isEmbedded: this.props.isEmbedded,
      addToast: this.addToast,
      removeToast: this.removeToast,
      toggleFooterAndHeaderOffset: this.toggleFooterAndHeaderOffset,
      federatedAccess: user.isShibbolethLogin(),
      showInfoMessage: this.state.showInfoMessage,
      toggleInfoMessage: this.toggleInfoMessage,
      courseId: this.state.courseId,
      handleCourseChange: this.handleCourseChange,
      handleCourseChangeById: this.handleCourseChangeById,
      isSelfStudyUser: this.state.isSelfStudyUser,
      isSelfStudyUserCallback: this.isSelfStudyUserCallback
    };

    const isClinicalSkills = productConfig.productCode === 'CK_MEDED_NURSING' && window.location.pathname.includes('clinical-skills');
    const hasCoreEducationAccess = user.hasCoreEducationAccess();

    const pageWrapperClass = classNames(
      { 'c-ckm-study-tools-login': this.props.studyToolsLoginType },
      { 's-ckm-nursing': productConfig.productCode === 'CK_MEDED_NURSING' },
      {' c-ckm-ecs': isClinicalSkills},
      { 'c-ckm-integrated-home': this.state.featureFlags?.integratedHomePage },
      this.props.pageWrapperClass
    );

    const isHubHomeRoute = () =>
      window.location.pathname === '/student' ||
      window.location.pathname === '/student/' ||
      window.location.pathname === '/student/nursing' ||
      window.location.pathname === '/student/nursing/';

    const isLoginRoute = () => window.location.pathname.includes('/login');
    const isSettingsRoute = () => window.location.pathname.includes('/profile');
    const isQuestionBankRoute = () => window.location.pathname.includes('/student/questionbanks');
    const isFullpageScroll = () => (!window.location.pathname.includes('/cases') && window.location.pathname.includes('/case')) || window.location.pathname.includes('/chart') || window.location.pathname.includes('/feedback');

    const containerClassName = classNames(
      'o-ckm-main-container',
      's-ckm-style-guide',
      'u-ckm-clearfix',
      { 'o-ckm-main-container--no-login': !user.isIndividual() },
      { 'o-ckm-main-container--practice': user.hasPracticeAccess() },
      { 'o-ckm-main-container--fullpage': isFullpageScroll() },
      { 'o-ckm-main-container--ecs': isClinicalSkills },
      { 'o-ckm-main-container--integrated-home': user.hasMoreThanOneApp() && isHubHomeRoute() },
      { 'o-ckm-main-container--integrated-product': user.hasMoreThanOneApp() && !isHubHomeRoute() && !isLoginRoute() && !isSettingsRoute()},
      { 'o-ckm-main-container--question-bank': isQuestionBankRoute() },
      { 'o-ckm-main-container--no-margin': this.state.isZoomed && !isFullpageScroll() },
      { 'core-top-navigation--enabled': hasCoreEducationAccess },
      { 'o-ckm-main-container--no-margin': this.state.isZoomed && !isFullpageScroll() }
    );

    const userInfo = {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      fences: user.fences,
      features: user.features,
      featureFlags: this.state.featureFlags,
      userId: user.user_id,
      paths: user.paths,
      usage_path_info: user.usage_path_info,
      search_indexes: user.getSearchIndexes(),
      userRoleName: user.userRoleName
    };

    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, { eolsLoginFinished: this.state.eolsLoginFinished, intl: this.props.intl });
    });

    // Meded-UI-Common components use the Product Code to determine URL path prefix
    // Since CK Resident uses the Student product code, a mock value needs to be sent to the header & footer
    const globalAppProductCode = productConfig.context === 'resident' ? 'CK_MEDED_RESIDENT' : productConfig.productCode;
    const isIntegratedHomePage = user.hasMoreThanOneApp() && this.props.userSettings.roleLMS !== 'INSTRUCTOR';

    const showHeaderExtension = !isIntegratedHomePage || (
      isIntegratedHomePage &&
      !window.location.pathname.includes('/student/browse/spc') &&
      !window.location.pathname.includes('/student/saved/cases') &&
      !(window.location.pathname.includes('/student/nursing/nnn') && !user.isNNNOnly())
    );
    return (
      <MainContext.Provider value={mainContextObject}>
        <div className={pageWrapperClass}>
          <ZoomHandler onZoomChange={this.handleZoomChange} />
          {!this.props.isEmbedded &&
            <div
              className={classNames(
                'o-ckm-header-wrapper',
                marginTopAnchorTag,
                {
                  'o-ckm-header-wrapper__no-fixed': isZoomed && !isFullpageScroll(),
                  'core-top-navigation--enabled': hasCoreEducationAccess
                }
            )} ref={this.headerRef}
            >
              {hasCoreEducationAccess && <CkReactCoreTopHeaderNavigation productName='CKS' />}
              {user.isClinicalSkillsOnly()
              ? <ECSGlobalAppHeader
                  productCode={globalAppProductCode}
                  userInfo={userInfo}
                  hideNavItems={this.props.hideNavItems}
                />
              : <ELSGlobalAppHeader
                  role={userRole}
                  userInfo={userInfo}
                  hasInstructorRole={user.hasInstructorRole()}
                  productCode={globalAppProductCode}
                  hideNavItems={this.props.hideNavItems}
                  userSettings={this.props.userSettings}
                  testMode
                  mockExamsVisible
                  hasUSMLEContent={hasUSMLEContent}
                  AKTExamsVisible
                  hasAKTExam={hasAKTExam}
                  isIntegratedHomePage={isIntegratedHomePage}
                  bookshelfButtonAnonymousFlyout={this.bookshelfButtonAnonymousFlyout()}
                />}
              <ELSSubNavbar
                role={userRole}
                userInfo={userInfo}
                hasInstructorRole={user.hasInstructorRole()}
                productCode={globalAppProductCode}
                hideNavItems={this.props.hideNavItems}
                userSettings={this.props.userSettings}
                testMode
                mockExamsVisible
                hasUSMLEContent={hasUSMLEContent}
                AKTExamsVisible
                hasAKTExam={hasAKTExam}
                isIntegratedHomePage={isIntegratedHomePage}
              />
              {showHeaderExtension &&
                <div className={`c-ckm-header-extension ${this.props.fixedHeaderClass} ${isIntegratedHomePage ? 'c-ckm-practice__header-extension--integrated-home' : ''}`} />}
            </div>}
          {!isZoomed && (<div className='o-ckm-header-offset' />)}
          <div
            className={containerClassName}
            role='main'
            id='main-content'
            name='main-content'
          >
            {children}
          </div>
          {pathChoices && <PathChoiceForm handlePathChoiceComplete={this.handlePathChoiceComplete} pathChoices={pathChoices} />}
          {!this.props.isEmbedded && !this.state.isOpenVideoDescriptionPanel &&
            <div className='o-ckm-footer-wrapper' ref={this.footerRef} id='footer' name='footer'>
              <AppFooter />
            </div>}
          {this.state.eolsLoginFinished && <ELSCloudFront />}
          {user.hasAssessmentAccess() && (
            <ELSIdleProvider
              timeout={ELSCommonUIConstants.IDLE_TIMEOUT_TIME}
              onSessionTimeout={this.handleSessionTimeout}
            />
          )}
          <ELSAccessibilityFocusState />
          {this.state.toastsConfig.length > 0 &&
            <ToastsContainer
              toasts={this.state.toastsConfig}
            />}
        </div>
      </MainContext.Provider>
    );
  }
}

PageWrapper.defaultProps = {
  userSettings: {},
  fixedHeaderClass: ''
};

PageWrapper.displayName = 'Page Wrapper';

export default injectIntl(PageWrapper);
