import React, {Fragment, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {defineMessages} from 'react-intl';
import StudyToolsPopup from '../StudyToolsPopup';
import InternalLink from '../common/InternalLink';
import InlineLoader from './InlineLoader';
import SpinnerLoader from './SpinnerLoader';
import {redeemAndSSO, disableVSTPopup, getMetadata} from '../../services';
import { MainContext } from '../../context/main-context';
import { vstReaderClicked } from '../../services/usageReporting';
import {trackEvent} from '../../utils/eventTrackUtils';
import { Button } from '@els/els-react--button';
import { Flyout } from '@els/els-react--flyout';
import { Icon } from '@els/els-react--icon';
import { AnonymousFlyoutBanner } from './AnonymousFlyoutBanner';

const messages = defineMessages({
  StudyToolsAdd: {
    id: 'StudyTools.links.addToBookshelf',
    defaultMessage: 'Add to Bookshelf'
  },
  StudyToolsLaunch: {
    id: 'StudyTools.links.launch',
    defaultMessage: 'View in Bookshelf'
  },
  StudyToolsOnline: {
    id: 'StudyTools.links.online',
    defaultMessage: 'online'
  },
  StudyToolsLaunchApp: {
    id: 'StudyTools.links.launchApp',
    defaultMessage: 'download the app'
  },
  StudyToolsUnavailable: {
    id: 'StudyToolsButton.message.studyToolsUnavailable',
    defaultMessage: 'Study tools unavailable'
  },
  addBookshelfToastHeader: {
    id: 'StudyTools.toast.addBookshelfToastHeader',
    defaultMessage: 'This book is added to your Bookshelf.'
  },
  addBookshelfToastBody: {
    id: 'StudyTools.toast.addBookshelfToastBody',
    defaultMessage: 'View this book on Bookshelf {bookshelfLink} or {appLink} on your devices to access it offline.'
  },
  errorBookshelfToastHeader: {
    id: 'StudyTools.toast.errorBookshelfToastHeader',
    defaultMessage: 'Error'
  },
  errorBookshelfToastBody: {
    id: 'StudyTools.toast.errorBookshelfToastBody',
    defaultMessage: 'There was an error trying to add the book. Please try again later.'
  },
  dontShowAgain: {
    id: 'StudyTools.toast.dontShowAgain',
    defaultMessage: 'Don\'t show this message again.'
  },
  dismiss: {
    id: 'StudyTools.toast.dismiss',
    defaultMessage: 'Dismiss'
  },
  flyoutAnonBannerHeaderText: {
    id: 'StudyTools.flyout.anonBannerHeaderText',
    defaultMessage: 'Want to read this offline, take notes and highlight?'
  },
  flyoutAnonBannerBodyText: {
    id: 'StudyTools.flyout.anonBannerBodyText',
    defaultMessage: 'Log in to add this book to Bookshelf e-reader app.'
  }
});

const sharedSuccessToastProperties = {
  isVisible: true,
  icon: '#icon-sprite_els-hmds-icon-checkmark',
  iconColor: 'positive',
  color: 'positive'
};

const sharedErrorToastProperties = {
  isVisible: true,
  icon: '#icon-sprite_els-hmds-icon-alert-triangle',
  iconColor: 'negative',
  color: 'negative'
};

const ContentStudyTools = (props) => {
  const context = useContext(MainContext);
  const { intl, activeIndex, addToast, user } = useContext(MainContext);
  const isAnon = user.isAnon();
  const isShown = () => window.localStorage.getItem('showBookshelfToast') || 'true';

  const {isBrowsePage, isBookBestBet} = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [vstData, setVstData] = useState({ suppressPopup: true });
  const [isLoadingBookshelf, setIsLoadingBookshelf] = useState(false);
  const [isShowToast, setIsShowToast] = useState(isShown());

  /**
   * Check VST Metadata API for redemption status
   */
  const checkIfRedeemed = () => {
    getMetadata(props.hubEid)
      .then(({data}) => {
        setIsLoading(false);
        setIsRedeemed(data.redeemed);
        setVstData({ suppressPopup: data.suppressPopup });
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  };

  const showToast = () => {
    const bookshelfLink = `${context.productConfig.path}/api/vst/redirect?hubEid=${props.hubEid}`;
    const appLink = 'https://service.elsevier.com/app/answers/detail/a_id/26012/supporthub/ckstudent/?utm_medium=ipm';
    isShown() !== 'false' &&
    addToast({
      title: intl.formatMessage(messages.addBookshelfToastHeader),
      body:
      `${intl.formatMessage(messages.addBookshelfToastBody, {
        bookshelfLink:
          `<div class='c-ckm-study-tools__toast-text'>
            <a href='#' onClick="window.open('${bookshelfLink}')" class="c-ckm-study-tools__link">
              ${intl.formatMessage(messages.StudyToolsOnline)}
            </a>
            <svg class="o-els-icon-svg o-els-icon-svg--1x c-ckm-study-tools__bookshelf-toast-icon">
              <use href='#icon-sprite_els-hmds-icon-new-window' />
            </svg>
          </div>`,
        appLink:
          `<div class='c-ckm-study-tools__toast-text'>
            <a href='${appLink}' target='_blank' class="c-ckm-study-tools__link">
              ${intl.formatMessage(messages.StudyToolsLaunchApp)}
            </a>
            <svg class="o-els-icon-svg o-els-icon-svg--1x c-ckm-study-tools__bookshelf-toast-icon">
              <use href='#icon-sprite_els-hmds-icon-new-window' />
            </svg>
          </div>`
      })}
        <div class="c-els-field c-els-field--checkbox c-ckm-study-tools__checkbox-field">
          <label class="c-els-field__label">
            <input type="checkbox" id="toastCheckbox" class="c-els-field__input" name="input-type-checkbox"/>
            <span class="c-els-field__label-text">
            <span class="c-els-field__switch">
            </span>${intl.formatMessage(messages.dontShowAgain)}</span>
          </label>
        </div>
        <button class='c-els-button--secondary c-ckm-study-tools__bookshelf-toast-button' id='toastButton'>
          ${intl.formatMessage(messages.dismiss)}
        </button>`,
      ...sharedSuccessToastProperties
    });
  };

  window.onclick = function (event) {
    if (event.target.id === 'toastButton') {
      event.target.closest('.c-els-toast').innerHTML = '';
    }
    if (event.target.id === 'toastCheckbox') {
      setIsShowToast(!event.target.checked);
    }
  };

  /**
   * Redeem book via VST API
   */
  const redeem = () => {
    setIsLoadingBookshelf(true);
    redeemAndSSO(props.hubEid)
      .then((response) => {
        showToast();
        setIsRedeemed(true);
        setVstData(response.data);
        setIsLoadingBookshelf(false);
      }).catch(() => {
        setHasError(true);
        setIsLoadingBookshelf(false);
        context.addToast({
          title: intl.formatMessage(messages.errorBookshelfToastHeader),
          body: intl.formatMessage(messages.errorBookshelfToastBody),
          ...sharedErrorToastProperties
        });
      });
  };

  /**
   * On component intialization, check redeemed status. Only runs once.
   */
  useEffect(() => {
    if (isAnon) {
      setIsLoading(false);
      setIsRedeemed(false);
    } else {
      checkIfRedeemed();
      window.localStorage.setItem('showBookshelfToast', isShowToast);
    }
  }, [isShowToast]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRedeemClick = (event) => {
    if (isAnon) {
      return;
    }
    redeem();
  };

  const handleLaunchClick = (event) => {
    vstReaderClicked(props.eid, activeIndex);

    const url = vstData.vBid ? `${event.currentTarget.href}&vBid=${vstData.vBid}` : event.currentTarget.href;
    trackEvent('linkOut', {
      content: [{
        id: url
      }]
    });
  };

  /**
   * Disable tooltip popup (via cookie)
   */
  const handleDisablePopupClick = (event) => {
    event.preventDefault();

    disableVSTPopup()
      .then(() => {
        setVstData({ ...vstData, suppressPopup: true });
      });
  };

  /**
   * Subcomponents
   */

  const ButtonLabel = () => {
    let message;

    if (hasError) {
      message = messages.StudyToolsUnavailable;
    } else if (isRedeemed) {
      message = messages.StudyToolsLaunch;
    } else {
      message = messages.StudyToolsAdd;
    }

    const translatedMessage = intl.formatMessage(message);
    if (hasError) { return (<></>); }
    return (
      <>
        {isRedeemed ? (
          <Icon
            a11y={{ name: translatedMessage }}
            sprite={Icon.Sprites.UP_RIGHT}
            id='bookshelf-btn-icon'
            className='c-ckm-study-tools__icon-launch'
            textAlignment='middle'
            size={Icon.Sizes.S}
            isVisible
            isTextFirst
          >
            {translatedMessage}
          </Icon>
        ) : (!isBrowsePage && !isBookBestBet
          ? <Icon
              a11y={{ name: translatedMessage }}
              sprite={Icon.Sprites.BOOK}
              id='bookshelf-btn-icon'
              className='c-ckm-toc__bookshelf-button-icon'
              textAlignment='middle'
              size={Icon.Sizes.S}
              isVisible
            >
            {translatedMessage}
          </Icon>
          : <Icon
              a11y={{ name: translatedMessage }}
              sprite={Icon.Sprites.BOOK}
              id='bookshelf-btn-icon'
              className='bookshelf-btn-icon'
              textAlignment='middle'
              size={Icon.Sizes.S}
              isVisible
            />
        )}
      </>
    );
  };

  /**
   * Render Logic
   */

  // if requests are in flight, render early with loading indicator
  if (isLoading) return <div><InlineLoader /></div>;
  if (isLoadingBookshelf) return <SpinnerLoader className={props.className} />;

  const href = `/api/vst/redirect?hubEid=${props.hubEid}`;

  // determine whether to show link or button
  const showLink = !hasError && isRedeemed;

  const buttonClassName = classNames(
    'button c-els-button c-els-button--small c-els-button--secondary c-ckm-study-tools__button u-els-width-1o1',
    {
      'c-ckm-button--disabled': hasError
    }
  );

  const buttonId = isRedeemed ? 'content_launch-bookshelf-btn' : '';

  return (
    <Flyout
      id='anonymous-flyout__bookshelf-button o-els-flex-layout__item'
      className='anonymous-flyout'
      flyout={
        <AnonymousFlyoutBanner
          headerText={intl.formatMessage(messages.flyoutAnonBannerHeaderText)}
          bodyText={intl.formatMessage(messages.flyoutAnonBannerBodyText)}
          testId='add-to-bookshelf'
        />
      }
      shouldCloseOnESC
      pointerType='secondary'
      theme='simple'
      isDisabled={!isAnon}
    >
      <div className={props.className}>
        <span>

          {showLink &&
            <InternalLink
              href={href}
              className={buttonClassName}
              onClick={handleLaunchClick}
              target='_blank'
              id={buttonId}
            >
              <ButtonLabel />
            </InternalLink>}
          {!showLink
        ? !isBrowsePage && !isBookBestBet
          ? <button
              className={buttonClassName}
              onClick={handleRedeemClick}
              disabled={hasError}
            >
            <ButtonLabel />
          </button>
          : <Flyout
              className='bookshelf-flyout'
              trigger='hover'
              placement='top'
              flyout={intl.formatMessage(messages.StudyToolsAdd)}
              shouldCloseOnESC
              id='save-btn-flyout'
              theme='simple'
            >
            <Button
              onClick={handleRedeemClick}
              type={Button.Types.LINK}
              className='c-ckm-savebutton'
              id=''
            >
              <ButtonLabel />
            </Button>
          </Flyout>
        : <></>}

        </span>
        {!props.suppressPopup && !vstData.suppressPopup && !hasError &&
          <StudyToolsPopup clickCallback={handleDisablePopupClick} />}
      </div>
    </Flyout>
  );
};

ContentStudyTools.propTypes = {
  /**
   * hubEID of Book
   */
  hubEid: PropTypes.string.isRequired,
  /**
   *  EID of book chapter (for usage reporting)
   */
  eid: PropTypes.string,
  /**
   * Force suppress popup/tooltip
  */
  suppressPopup: PropTypes.bool
};

export default ContentStudyTools;
