import React from 'react';
import PropTypes from 'prop-types';

const createMarkup = (htmlString) => ({__html: htmlString});

const HTMLString = (props) => {
  const html = createMarkup(props.content);
  const langProps = props.lang ? {lang: props.lang} : {};

  return (
    <span dangerouslySetInnerHTML={html} {...langProps} data-testid='htmlString' />
  );
};

export default HTMLString;

HTMLString.propTypes = {
  content: PropTypes.string.isRequired
};
