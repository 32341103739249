import React, {useState} from 'react';
import {string, func, object} from 'prop-types';
import classNames from 'classnames';
import {defineMessages} from 'react-intl';
import useIntl from '../../hooks/use-intl';
import {KEYS} from '../../constants';
import '../../assets/img/icon-search-black.svg';

const messages = defineMessages({
  clearSearch: {
    id: 'TOCSearchForm.aria.clearSearch',
    defaultMessage: 'Clear search'
  },
  submitSearch: {
    id: 'TOCSearchForm.aria.submitSearch',
    defaultMessage: 'Submit search'
  }
});

const generateClearButtonClass = searchText => classNames(
  'c-ckm-generic-search-bar__button', {
    'u-ckm-hidden': searchText.length === 0
  });

const GenericSearchBar = props => {
  const [query, setQuery] = useState('');
  const intl = useIntl();
  const onSubmitSearch = () => {
    props.onSubmit && props.onSubmit(query);
  };

  const clearQuery = () => {
    setQuery('');
    props.onClear && props.onClear();
  };

  return (
    <div className={classNames('u-flex-space-between', props.containerClass)}>
      <input
        className={props.placeholderClass}
        type='text'
        placeholder={props.placeholder}
        value={query}
        onChange={e => setQuery(e.target.value)}
        onKeyDown={(e) => e.key === KEYS.Enter && onSubmitSearch()}
        aria-label={props.placeholder}
      />
      <button className={generateClearButtonClass(query)} onClick={clearQuery} ref={props.clearRef} type='button' aria-label={intl.formatMessage(messages.clearSearch)}>
        <span className='c-ckm-icon c-ckm-icon--cross' />
      </button>
      <button className='c-ckm-generic-search-bar__button' type='button' onClick={onSubmitSearch} aria-label={intl.formatMessage(messages.submitSearch)}>
        <svg width='16' height='16' viewBox='0 0 16 16'>
          <use xlinkHref='#icon-search-black' />
        </svg>
      </button>
    </div>
  );
};

GenericSearchBar.propTypes = {
  containerClass: string,
  placeholderClass: string,
  placeholder: string,
  onSubmit: func,
  onClear: func,
  clearRef: object
};

GenericSearchBar.displayName = 'GenericSearchBar';
export default GenericSearchBar;
