import React, {useEffect, useState} from 'react';
import {loadModule} from 'cld3-asm';

const withLangDetector = (Component) => {
  return (props) => {
    const [langDetector, setLangDetector] = useState(null);

    useEffect(() => {
      const loadLangDetector = async () => {
        const cldFactory = await loadModule();
        return cldFactory.create(0, 1000);
      };

      loadLangDetector().then((detector) => {
        setLangDetector(detector);
      });

      return () => {
        if (langDetector) {
          langDetector.dispose();
        }
      };
    }, []);

    return <Component {...props} langDetector={langDetector} />;
  };
};

export default withLangDetector;
