import React, { useContext } from 'react';
import { defineMessages } from 'react-intl';
import { MainContext } from '../../context/main-context';
import { Button } from '@els/els-react--button';

const messages = defineMessages({
  login: {
    id: 'AnonymousLoginBanner.loginButtonLabel',
    defaultMessage: 'Log in'
  },
  register: {
    id: 'AnonymousLoginBanner.registerButtonLabel',
    defaultMessage: 'Register'
  }
});

export const AnonymousFlyoutBanner = ({ headerText, bodyText, testId = '' }) => {
  const { intl, doRedirect } = useContext(MainContext);
  return (
    <div
      className='anonymous-flyout-banner'
      data-testid='anonymous-flyout-banner'
    >
      <h4 className='anonymous-flyout-banner__header'>{headerText}</h4>
      <p className='anonymous-flyout-banner__body'>{bodyText}</p>
      <div className='anonymous-flyout-banner__buttons-container'>
        <Button
          id='login-button'
          type='link'
          data-testid={`anonymous${testId && '_' + testId}_login-btn`}
          aria-label={intl.formatMessage(messages.login)}
          onClick={() => { doRedirect('/login'); }}
          className='login-button'
        >
          {intl.formatMessage(messages.login)}
        </Button>
        <Button
          id='register-button'
          type='link'
          data-testid={`anonymous_${testId && '_' + testId}_register-btn`}
          aria-label={intl.formatMessage(messages.register)}
          onClick={() => { doRedirect('/registration'); }}
          className='register-button'
        >
          {intl.formatMessage(messages.register)}
        </Button>
      </div>
    </div>
  );
};
