import React, { useEffect, useRef, useState, useContext } from 'react';
import ResizeDetector from 'react-resize-detector';
import { Flyout } from '@els/els-react--flyout';
import { MainContext } from '../../context/main-context';

const RecentActivitiesItemIA = (props) => {
  const titleRef = useRef(null);
  const titleRefHidden = useRef(null);
  const subTitleRef = useRef(null);
  const subTitleRefHidden = useRef(null);
  const [isDisabledTitleTooltip, setIsDisabledTitleTooltip] = useState(true);
  const [isDisabledSubTitleTooltip, setIsDisabledSubTitleTooltip] = useState(true);
  const { doRedirect } = useContext(MainContext);

  useEffect(() => {
    disabledTooltipHandler();
  }, [props.title, props.subTitle]);

  const onResize = () => {
    disabledTooltipHandler();
  };

  const disabledTooltipHandler = () => {
    if (titleRef.current && titleRefHidden.current) {
      setIsDisabledTitleTooltip(!(titleRefHidden.current.clientHeight > titleRef.current.clientHeight));
    }

    if (subTitleRef.current && subTitleRefHidden.current) {
      setIsDisabledSubTitleTooltip(!(subTitleRefHidden.current.clientHeight > subTitleRef.current.clientHeight));
    }
  };

  return (
    <ResizeDetector
      handleWidth
      onResize={onResize}
      refreshMode='debounce'
      refreshOptions={{trailing: true}}
      render={({ width }) =>
        <button
          onClick={() => doRedirect(props.contentLink.props.children.props.href)}
          data-testid={props.testId}
          className='recent-activities-widget__wrapper'
        >
          <div className='recent-activities-widget__content-activity'>
            <div className='recent-activities-widget__content-activity-container'>
              <div className='recent-activities-widget__content-activity-image'>
                {props.image}
              </div>
              <div className='recent-activities-widget__content-activity-text'>
                <h1 className='recent-activities-widget__content-activity-continue-reading' ref={titleRef}>
                  <Flyout
                    trigger='hover'
                    placement='top'
                    theme='simple'
                    isDisabled={isDisabledTitleTooltip}
                    flyout={props.title}
                    shouldCloseOnESC
                    id='continue-reading-title-btn-flyout'
                  >
                    {props.title}
                  </Flyout>
                </h1>
                <h1
                  className='recent-activities-widget__content-activity-continue-reading recent-activities-widget__content-activity-continue-reading--hidden'
                  ref={titleRefHidden}
                >
                  {props.title}
                </h1>
                <p ref={subTitleRef}>
                  <Flyout
                    trigger='hover'
                    placement='top'
                    theme='simple'
                    isDisabled={isDisabledSubTitleTooltip}
                    flyout={props.subTitle}
                    shouldCloseOnESC
                    id='continue-reading-subtitle-btn-flyout'
                  >
                    {props.subTitle}
                  </Flyout>
                </p>
                <p ref={subTitleRefHidden} className='recent-activities-widget__content-activity-continue-reading-subtitle--hidden'>
                  {props.subTitle}
                </p>
                <div className='recent-activities-widget__content-activity-button'>
                  {props.contentLink}
                </div>
              </div>
            </div>

          </div>
        </button>}
    />
  );
};

RecentActivitiesItemIA.displayName = 'RecentActivitiesItemIA';

export default RecentActivitiesItemIA;
