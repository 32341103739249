import React, { useContext } from 'react';
import { defineMessages } from 'react-intl';
import { Button } from '@els/els-react--button';
import { MainContext } from '../../context/main-context';
import '../../assets/img/book.svg';
import {Icon} from '@els/els-react--icon';

const messages = defineMessages({
  noSavedBooks1: {
    id: 'Home.message.noSavedBooks1',
    defaultMessage: 'You have no saved books yet. Browse books and click'
  },
  noSavedBooks2: {
    id: 'Home.message.noSavedBooks2',
    defaultMessage: 'to add a book to your Saved Books.'
  },
  loginButton: {
    id: 'Home.button.login',
    defaultMessage: 'Log in'
  },
  browseBooksButton: {
    id: 'Home.button.browseBooks',
    defaultMessage: 'Browse all books'
  },
  starIcon: {
    id: 'Home.button.starIcon',
    defaultMessage: 'Star Icon'
  },
  noSavedBooks3: {
    id: 'Home.message.noSavedBooks3',
    defaultMessage: 'Access your favorite books later'
  },
  noSavedBooks4: {
    id: 'Home.message.noSavedBooks4',
    defaultMessage: 'Log in to save your books in ClinicalKey Student'
  }
});

const SavedEmpty = (props) => {
  const { user, intl, doRedirect } = useContext(MainContext);

  const hasAnonAccess = user.hasAnonAccess();

  return (
    <div className='c-ckm-saved__empty-container'>
      <svg className='c-ckm-saved__graphic'>
        <use xlinkHref='#book' />
      </svg>
      <h3>
        {hasAnonAccess ? (
          <>
            {intl.formatMessage(messages.noSavedBooks3)}
          </>
        ) : (
          <>
            {intl.formatMessage(messages.noSavedBooks1)}
            <Icon
              a11y={{ name: intl.formatMessage(messages.starIcon) }}
              sprite={Icon.Sprites.STAR}
              id='saved-books-star-icon'
              className='u-els-margin-right-1o4 u-els-margin-bottom-1o8 u-els-margin-left-1o4'
              textAlignment='sub'
              color='secondary'
              size={Icon.Sizes.S}
              isVisible
            />
            {intl.formatMessage(messages.noSavedBooks2)}
          </>
        )}
      </h3>
      {hasAnonAccess && (
        <p>
          {intl.formatMessage(messages.noSavedBooks4)}
        </p>
      )}
      <div className='c-ckm-showcase-catalog__button-container'>
        {hasAnonAccess ? (
          <Button
            type={Button.Types.PRIMARY}
            className=''
            // the redirect to login page will work after it'll become the home page
            onClick={() => { doRedirect('/login'); }}
            title={intl.formatMessage(messages.loginButton)}
            id='btn-home-login'
            data-testid='anonymous_saved-books_login-btn'
          >
            {intl.formatMessage(messages.loginButton)}
          </Button>
        ) : (
          <Button
            type={Button.Types.PRIMARY}
            className=''
            onClick={() => { doRedirect('/browse/books'); }}
            title={intl.formatMessage(messages.browseBooksButton)}
            id='btn-home-browse-books'
          >
            {intl.formatMessage(messages.browseBooksButton)}
          </Button>
        )}
      </div>
    </div>
  );
};

SavedEmpty.displayName = 'SavedEmpty';

export default SavedEmpty;
