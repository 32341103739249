const pendoLoaded = () => (window.pendo && window.pendo.track);
const eventQueue = [];

const pendoTrack = async (eventName) => {
  const eventObject = {
    eventName: eventName,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight
  };
  if (!pendoLoaded()) {
    eventQueue.push(eventObject);
  } else {
    window.pendo.track(eventObject.eventName, {
      screenWidth: eventObject.screenWidth,
      screenHeight: eventObject.screenHeight
    });
  }
};

const processQueuedEvents = async () => {
  if (pendoLoaded()) {
    console.log('Pendo loaded, processing queued events, queue size = ', eventQueue.length);
    while (eventQueue.length > 0) {
      const event = eventQueue.shift();
      window.pendo.track(event.eventName, {
        screenWidth: event.screenWidth,
        screenHeight: event.screenHeight
      });
    }
  }
};

const waitForPendo = async () => {
  while (!pendoLoaded()) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
  await processQueuedEvents();
};

waitForPendo();

const pendoTrackScreenResButtons = (eventName) => {
  pendoTrack(`${eventName} button Clicked`);
};

const pendoTrackScreenResPage = (eventName) => {
  pendoTrack(eventName);
};

const getQueueLength = () => {
  return eventQueue.length;
};

module.exports = {
  pendoTrackScreenResPage,
  pendoTrackScreenResButtons,
  getQueueLength
};
