import React, { Component } from 'react';
import { Flyout } from '@els/els-react--flyout';
import HTMLString from '../../components/common/HTMLString';
import {
  getImageThumbnailUrl,
  getThumbnailFromCoverImage,
  getVideoThumbnailUrl
} from '../../utils/stringUtils';
import PropTypes from 'prop-types';
import CKApi from '../../services/api-helper';
import { defineMessages } from 'react-intl';
import SaveButton from '../../components/common/SaveButton';
import AuthorListV2 from '../../components/common/AuthorListV2';
import BrokenImage from '../../assets/img/ico_broken-img.png';
import { MainContext } from '../../context/main-context';
import ContentStudyTools from '../../components/common/ContentStudyTools';
import { CONTENT_TYPE_NAMES } from '../../constants';
import MediaPopout from '../../components/common/MediaPopout';
import ANCPopout from '../../components/common/ANCPopout';
import InternalContentLink from '../../components/common/InternalContentLink';
import { Icon } from '@els/els-react--icon';
import { handleEditionsResponse } from '../../utils/editionUtils';
import EditionNotificationBanner from '../../components/common/EditionNotificationBanner';
import ANCIcon from '../../components/common/ANCIcon';
import { AnonymousFlyoutBanner } from '../../components/common/AnonymousFlyoutBanner';

const messages = defineMessages({
  published: {
    id: 'TitleResultThumbnail.message.published',
    defaultMessage: 'Published'
  },
  flyoutAnonBannerHeaderText: {
    id: 'TitleResultThumbnail.flyout.anonBannerHeaderText',
    defaultMessage: 'Want to read this later?'
  },
  flyoutAnonBannerBodyText: {
    id: 'TitleResultThumbnail.flyout.anonBannerBodyText',
    defaultMessage: 'Log in to add this book to your “Saved books” list.'
  }
});

class TitleResultThumbnail extends Component {
  static propTypes = {
    coverimage: PropTypes.array,
    hubeid: PropTypes.string,
    sourcetitle: PropTypes.string,
    focusIndex: PropTypes.bool,
    isSaved: PropTypes.bool,
    showButtons: PropTypes.bool,
    isCollectionItem: PropTypes.bool,
    savedId: PropTypes.string,
    issn: PropTypes.string,
    coverImages: PropTypes.array,
    refImages: PropTypes.array,
    type: PropTypes.string,
    itemTypeName: PropTypes.string,
    sectionId: PropTypes.string,
    eid: PropTypes.string,
    imageRef: PropTypes.string,
    tile: PropTypes.bool,
    pubdate: PropTypes.number,
    authorlist: PropTypes.array,
    isBrowse: PropTypes.bool,
    srcType: PropTypes.array,
    refType: PropTypes.string
  };

  static defaultProps = {
    showButtons: true
  };

  state = {
    isSaved: this.props.isSaved || false,
    isShowPopup: false,
    oldEdition: {},
    newEdition: {},
    isOld: false,
    isShowBanner: true
  };

  static contextType = MainContext;
  linkRef = React.createRef();

  isImage = this.props.type === CONTENT_TYPE_NAMES.IMAGE;
  isVideo = this.props.type === CONTENT_TYPE_NAMES.VIDEO;
  isANC = this.props.type === CONTENT_TYPE_NAMES.ANC;
  isBook = this.props.type === CONTENT_TYPE_NAMES.BOOK;
  isNNN = this.props.type === CONTENT_TYPE_NAMES.NIC ||
    this.props.type === CONTENT_TYPE_NAMES.NOC ||
    this.props.type === CONTENT_TYPE_NAMES.DIAGMED ||
    this.props.type === CONTENT_TYPE_NAMES.NANDA;

  componentDidMount () {
    if (this.isBook) {
      this.getEditions(this.props.hubeid);
    }
    if (this.props.focusIndex) {
      this.linkRef.current.focus();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.focusIndex) {
      this.thumbnail.focus();
    }
    if (prevProps.isSaved !== this.props.isSaved) {
      this.setState({
        isSaved: this.props.isSaved
      });
    }
  }

  getEditions = (eid) => {
    CKApi.get(`/student/api/${eid}/editions`)
      .then(data => {
        const editionResponse = handleEditionsResponse(data, this.props.hubeid);
        this.setState({
          oldEdition: editionResponse.oldEdition,
          newEdition: editionResponse.newEdition,
          isOld: editionResponse.isOld
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getImgSrc = () => {
    const { refImages, coverimage, coverImages, imageRef } = this.props;

    if (this.isVideo) {
      const videoThumbnailUrl = getVideoThumbnailUrl(refImages);
      return videoThumbnailUrl && videoThumbnailUrl !== ''
        ? videoThumbnailUrl
        : BrokenImage;
    }

    if ((this.isANC || this.isBook) && coverimage) {
      return getImageThumbnailUrl(coverimage) ?? BrokenImage;
    }

    if (this.isImage && imageRef) {
      return imageRef;
    }

    if (coverImages && coverImages.length > 0) {
      return getThumbnailFromCoverImage(coverImages) ?? BrokenImage;
    }

    return BrokenImage;
  };

  handleClick = (evt) => {
    const { doRedirect } = this.context;
    if (this.isImage || this.isANC) {
      if (this.props.srcType && this.props.srcType.includes('Video')) {
        doRedirect(`/content/anc/video/${this.props.eid}`);
      } else {
        evt.preventDefault();
        this.setState({ isShowPopup: true });
      }
    } else {
      evt.preventDefault();
      this.setState({ isShowPopup: true });
    }
  };

  handlePopoutClose = () => {
    this.setState({ isShowPopup: false });
  };

  handleBannerClose = () => {
    this.setState({ isShowBanner: false });
  };

  returnImagesAndAncThumbnail = (imgSrc, isANC) => {
    return (
      <>
        <button
          className='c-ckm-thumbnail__button c-ckm-browse-list__link-thumbnail'
          onClick={this.handleClick}
          ref={this.linkRef}
          disabled={!this.props.isEntitled}
          data-testid='thumbnailButton'
        >
          {this.props.isCollectionItem && isANC
            ? <ANCIcon
                showPopup={this.props.description || 'ANC'}
                type={this.props.srcType}
                intl={this.props.intl}
                size={Icon.Sizes.L}
                isVisible
              />
          : <img
              className='c-ckm-browse-list__image'
              src={imgSrc}
              alt={`Cover of ${this.props.sourcetitle}`}
            />}

        </button>
      </>
    );
  };

  returnImagesAndAncTitle = (linkClassName) => {
    return (
      <button
        className={`c-ckm-link c-ckm-browse-list__link-nowidth ${linkClassName}`}
        onClick={this.handleClick}
        disabled={!this.props.isEntitled}
      >
        {this.props.sourcetitle || this.props.description}
      </button>
    );
  };

  pubDate = (pubdate) => {
    return new Date(Number(pubdate));
  };

  render () {
    const { intl, user } = this.context;
    const linkClassName = this.props.isEntitled ? '' : 'disabled-link';
    const imgSrc = this.props.imageRef
      ? this.props.imageRef
      : this.props.coverimage
        ? getImageThumbnailUrl(this.props.coverimage)
        : BrokenImage;

    const isAnonUser = user.isAnon();
    let popupComponent = null;
    if (this.state.isShowPopup) {
      if (this.isANC) {
        popupComponent = (
          <ANCPopout
            {...this.props}
            handlePopoutClose={this.handlePopoutClose}
          />
        );
      } else {
        popupComponent = (
          <MediaPopout
            {...this.props}
            handlePopoutClose={this.handlePopoutClose}
          />
        );
      }
    }

    const renderThumbnail = () => {
      if (this.isImage || this.isANC) {
        return this.returnImagesAndAncThumbnail(imgSrc, this.isANC);
      } else {
        return (
          <InternalContentLink
            contentType={this.props.type}
            hubeid={this.props.hubeid}
            eid={this.props.eid}
            issn={this.props.issn}
            sectionId={this.props.sectionId}
            className={`c-ckm-browse-list__link ${linkClassName}`}
            linkRef={this.linkRef}
            isEntitled={this.props.isEntitled}
          >
            <img
              className='c-ckm-browse-list__image'
              src={this.props.isCollectionItem ? this.getImgSrc() : imgSrc}
              alt={`Cover of ${this.props.sourcetitle}`}
            />
          </InternalContentLink>
        );
      }
    };

    const renderContent = () => {
      if (this.props.tile) {
        return (
          <div>
            <div className='c-ckm-browse-list-subheader'>
              {this.props.itemTypeName ?? this.props.type}
            </div>
            {(this.isImage || this.isANC) && this.returnImagesAndAncTitle()}
            {!(this.isImage || this.isANC) && (
              <InternalContentLink
                contentType={this.props.type}
                hubeid={this.props.hubeid}
                eid={this.props.eid}
                issn={this.props.issn}
                sectionId={this.props.sectionId}
                className='c-ckm-browse-list__link'
                linkRef={this.linkRef}
              >
                <HTMLString content={this.props.sourcetitle} />
              </InternalContentLink>
          )}
            {this.props.sourcetitle && (
              <div className='c-ckm-browse-list-subheader'>
                {this.props.description}
              </div>
          )}
          </div>
        );
      } else {
        return (
          <div className='o-els-flex-layout__item o-els-flex-layout__item--grow'>
            <InternalContentLink
              contentType={this.props.type}
              eid={this.props.eid}
              hubeid={this.props.hubeid}
              issn={this.props.issn}
              sectionId={this.props.sectionId}
              className='c-ckm-browse-list__link'
              linkRef={this.linkRef}
            >
              <HTMLString content={this.props.sourcetitle} />
            </InternalContentLink>
            {this.props.isBrowse && (
              <>
                <div className='c-ckm-browse-list-authors'>
                  <AuthorListV2 authors={this.props.authorlist} />
                </div>
                <div className='c-ckm-browse-list-pubdate'>
                  {intl.formatMessage(messages.published)}{' '}
                  {this.pubDate(this.props.pubdate).getFullYear()}
                </div>
              </>
          )}
          </div>
        );
      }
    };

    return (
      <>
        {popupComponent}
        {renderThumbnail()}
        <div className='c-ckm-browse-list__row o-els-flex-layout o-els-flex-layout__item o-els-flex-layout__item--grow'>
          {renderContent()}
          {this.props.showButtons && (
            <div className='c-ckm-browse-list__buttons-group o-els-flex-layout'>
              {this.props.tile ? (
              ''
            ) : (
              <ContentStudyTools
                className='c-ckm-browse-list__bookshelf-button'
                hubEid={this.props.hubeid}
                suppressPopup
                isBrowsePage
              />
            )}
              <div className='c-ckm-browse-list__buttons-visible'>
                <Flyout
                  id='anonymous-flyout__title-result-thumbnail'
                  className='anonymous-flyout'
                  flyout={
                    <AnonymousFlyoutBanner
                      headerText={this.context.intl.formatMessage(messages.flyoutAnonBannerHeaderText)}
                      bodyText={this.context.intl.formatMessage(messages.flyoutAnonBannerBodyText)}
                      testId='save-book'
                    />
                  }
                  shouldCloseOnESC
                  pointerType='secondary'
                  theme='simple'
                  isDisabled={!isAnonUser}
                >
                  <SaveButton
                    showLabel={false}
                    hubeid={this.props.hubeid}
                    sourcetitle={this.props.sourcetitle}
                    isSaved={this.state.isSaved}
                    savedId={this.props.savedId}
                  />
                </Flyout>
              </div>
            </div>
        )}
        </div>
        {this.isBook && this.state.isOld && this.props.tile && this.state.isShowBanner && (
          <EditionNotificationBanner intl={this.context.intl} isOld={this.state.isOld} oldEdition={this.state.oldEdition} newEdition={this.state.newEdition} onClose={this.handleBannerClose} />
        )}
      </>
    );
  }
}

TitleResultThumbnail.displayName = 'TitleResultThumbnail';

export default TitleResultThumbnail;
